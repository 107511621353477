/* eslint-disable no-useless-escape */
export const THEMES = {
  LIGHT: 'LIGHT'
};

export const OTHER = 'static/images/logo/question.svg';
export const TIKTOK = 'static/images/logo/tiktok.svg';
export const YOUTUBE = 'static/images/logo/youtube.svg';
export const FACEBOOK = 'static/images/logo/facebook.svg';
export const INSTAGRAM = 'static/images/logo/instagram.svg';

export const SOCIALMEDIA = {
  1: TIKTOK,
  4: INSTAGRAM
};

export const TIKTOK_URL = 'https://www.tiktok.com/';

export const SOURCER_MANAGER = 'SOURCER_MANAGER';
export const SOURCER = 'SOURCER';
export const CREATOR_SOURCER = 'CREATOR_SOURCER';
export const CREATOR_SOURCER_MANAGER = 'CREATOR_SOURCER_MANAGER';
export const CONTENT_REVIEWER = 'CONTENT_REVIEWER';

export const ROLES = {
  SOURCER_MANAGER: 'Manager',
  SOURCER: 'Sourcer',
  CREATOR_SOURCER: 'Creator Sourcer',
  CREATOR_SOURCER_MANAGER: 'Creator Manager',
  CONTENT_REVIEWER: 'Content Reviewer'
};

export const videoStatus = {
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED'
};

export const batchSize = 50;
export const LIMIT = 25;
export const PENDING_VIDEOS_PATH = 'pending_videos/';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const MAX_DAYS = 31;
export const MAX_STEPS = 2;

export const SOURCER_REPORT_TYPES = {
  REVIEWS_BY_DATE: 'REVIEWS_BY_DATE',
  SUB_REJECTION_REASONS: 'SUB_REJECTION_REASONS',
  SERVER_REJECTION_REASONS: 'SERVER_REJECTION_REASONS'
};

export const REJECTION_REASONS = {
  REJECTED_BY_LICENSOR: 'Creator was not approved',
  REJECTED_DUPLICATE: 'Creator already sourced',
  REJECTED_BLACKLIST: 'Blacklisted creator'
};

export const FILTER_STATUSES = {
  REVIEWED: 'REVIEWED',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  DUPLICATE: 'DUPLICATE'
};

export const CUSTOM_FILTER_STATUSES = {
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  APPROVED: 'APPROVED',
  REJECTED_BY_LICENSOR: 'REJECTED_BY_LICENSOR',
  REJECTED_DUPLICATE: 'REJECTED_DUPLICATE',
  REJECTED_BLACKLIST: 'REJECTED_BLACKLIST'
};

export const STATUS = [
  {
    id: 1,
    value: FILTER_STATUSES.REVIEWED,
    name: 'Reviewed'
  },
  {
    id: 1,
    value: FILTER_STATUSES.PENDING,
    name: 'Pending'
  },
  {
    id: 2,
    value: FILTER_STATUSES.DUPLICATE,
    name: 'Duplicate'
  },
  {
    id: 0,
    value: FILTER_STATUSES.PROCESSING,
    name: 'Processing'
  }
];

export const CUSTOM_STATUS = [
  {
    value: CUSTOM_FILTER_STATUSES.APPROVED,
    name: 'Approved'
  },
  {
    value: CUSTOM_FILTER_STATUSES.PENDING,
    name: 'Pending'
  },
  {
    value: CUSTOM_FILTER_STATUSES.ASSIGNED,
    name: 'Pending review'
  },
  {
    value: CUSTOM_FILTER_STATUSES.REJECTED_BY_LICENSOR,
    name: 'Rejected'
  },
  {
    value: CUSTOM_FILTER_STATUSES.REJECTED_DUPLICATE,
    name: 'Duplicate'
  },
  {
    value: CUSTOM_FILTER_STATUSES.REJECTED_BLACKLIST,
    name: 'Blacklisted'
  }
];

export const AGGREGATED_REPORT_TITLE = {
  approved: 'Total Approved',
  rejected: 'Total Rejected',
  reviewed: 'Licensor Reviewed',
  submitted: 'Total Submitted',
  signed_video_reports: 'Total Signed',
  rejected_duplicate: 'Duplicates',
  avg_approval: 'Apprv. Daily Avg.',
  avg_submitted: 'Sub. Daily Avg.'
};

export const TEAMS = [
  { team: 'UGC_30_SEC_PLUS', team_name: 'UGC Content' },
  { team: 'RUSSIAN_VIDEOS', team_name: 'Russian Videos' },
  { team: 'LONG_FORM_VIDS', team_name: 'Long Form Videos' },
  { team: 'INSTAGRAM_UGC', team_name: 'Instagram UGC' }
];

export const tiktokHandleRegex =
  /(^(?!.*\$)(?!(:|\/)+)[a-zA-Z0-9_.]{1,45}$)|(https:\/\/(www\.)?tiktok\.com\/(@\w{1,}).*)/;
export const instagramUrlRegex =
  /^[A-Za-z0-9._-]{3,50}|https:\/\/(?:www\.)?instagram\.com\/.{3,}$/;
